<script setup lang="ts">
import { useRouter } from "vue-router";

const router = useRouter();
</script>

<template>
  <div class="footer">
    <div class="footer-items">
      <div class="footer-item footer-item-logo footer-item-1">
        <a href="https://www.kyoto-u.ac.jp/en" target="_blank">
          <img
            alt="Kyoto Logo"
            class="footer-logo footer-logo-kyoto"
            src="@/assets/images/kyoto_logo2.png" />
        </a>
      </div>
      <div class="footer-item footer-item-2">
        <a
          class="footer-item-router-link"
          href="/about"
          @click.prevent="router.push('about')"
          >About</a
        >
      </div>
      <div class="footer-item footer-item-logo footer-item-3">
        <a href="https://www.uzh.ch" target="_blank">
          <img
            alt="UZH Logo"
            class="footer-logo footer-logo-uzh"
            src="@/assets/images/uzh_logo.svg" />
        </a>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/variables.scss";

.footer {
  background-color: rgb(255, 255, 255, 0.6);
  color: white;
  box-shadow: $box-shadow;
}
.footer-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 4px;
}

.footer-item {
  @include small-normal;
}

.footer-item-logo {
  flex: 1;
  display: flex;
  justify-content: center;
}

.footer-item-1 {
  justify-content: flex-start;
}

.footer-item-2 {
  justify-content: center;
}

.footer-item-3 {
  justify-content: flex-end;
}

.footer-logo {
  background-color: black;
  transition: background-color 0.3s ease;
  mask-repeat: no-repeat;
  mask-size: contain;
}

.footer-logo-uzh {
  width: 175px;
  margin-right: 8px;
  mask-image: url("@/assets/images/uzh_logo.svg");
}

.footer-logo-kyoto {
  width: 225px;
  margin-left: 8px;
  background-color: transparent;
}

@media (max-width: $phone-breakpoint) {
  .footer-logo-uzh {
    width: 125px;
  }
  .footer-logo-kyoto {
    width: 150px;
  }
}

.footer-logo:hover {
  opacity: 0.86;
}

.footer-item-router-link {
  color: $background-gradient-2;
  color: black;
  text-decoration: none;
  @include medium-normal;
  transition: color 0.3s ease;
  &:hover {
    text-decoration: underline;
    color: black;
  }
}

@media (max-width: $phone-breakpoint) {
  .footer-item {
    text-align: center;
    margin: 8px 0;
  }
}
</style>
