// hardcoded for now
export const getBaseStrapiUrl = () => {
  if (document.URL.startsWith("http://localhost")) {
    return "http://localhost:1337";
  } else if (document.URL.startsWith("https://aipolicy.ius.uzh.ch")) {
    return "https://aipolicy-backend.ius.uzh.ch";
  }
};
export const getStrapiAdminUrl = () => {
  return getBaseStrapiUrl() + "/admin";
};
// hardcoded for now
export const isProduction = () => {
  return document.URL.startsWith("https://aipolicy.ius.uzh.ch");
};
