<script setup lang="ts">
import Dialog from "primevue/dialog";
import { useApiStore } from "@/stores/useApiStore";

const apiStore = useApiStore();
const closeDialog = () => {
  apiStore.data.dialog.type = "closed";
};
</script>

<template>
  <div v-if="apiStore.data.dialog.type !== 'closed'">
    <Dialog
      class="modal-window-publications"
      :visible="true"
      :draggable="false"
      :maximizable="false"
      @update:visible="closeDialog"
      modal
      header="View publications">
      <div class="modal-window-container">
        <div
          class="team-member-publications"
          v-if="apiStore.data.dialog.data && apiStore.data.dialog.data.length">
          <ul>
            <li
              v-for="publication in apiStore.data.dialog.data"
              :key="publication.title">
              {{ publication.title }} -
              <span class="publication-date"
                >{{
                  new Intl.DateTimeFormat("de-DE", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  }).format(new Date(publication.date))
                }}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <template #footer>
        <slot name="footer" />
      </template>
    </Dialog>
  </div>
</template>

<style lang="scss">
@import "@/assets/variables.scss";
.modal-window-publications {
  max-width: 80ch;
}
.publication-date {
  font-style: italic !important;
}
.p-dialog-title {
  @include large-normal;
}

.team-member-publications {
  text-align: left;
}

.team-member-publications ul {
  padding-left: 1rem;
}

.team-member-publications li {
  margin-bottom: 0.5rem;
}
</style>
