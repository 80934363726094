import {
  createRouter,
  createWebHistory,
  type RouteRecordRaw,
} from "vue-router";
import Home from "../views/HomeView.vue";

import type { Component } from "vue";

declare module "vue-router" {
  interface RouteMeta {
    // allowed Roles for the route, "ADMIN" is always allowed
    ROLES?: string[];
    layout?: Component;
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/compare",
    name: "Compare",
    component: () => import("../views/CompareView.vue"),
  },
  {
    path: "/team",
    name: "Team",
    component: () => import("../views/TeamView.vue"),
  },
  {
    path: "/sources",
    name: "Sources",
    component: () => import("../views/SourcesView.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/AboutView.vue"),
  },
  {
    path: "/assets",
    name: "Assets",
    component: () => import("../views/AssetsView.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("../views/NotFoundView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.afterEach((to) => {
  document.body.className = to.name
    ? `page-${to.name.toString().toLowerCase()}`
    : "";
});

export default router;
