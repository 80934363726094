// manages all data fetching for the frontend

import { ref } from "vue";
import { defineStore } from "pinia";
//import type { Country } from "@/models/app";
import placeholderImage from "@/assets/images/missing_foto.png";

import axios from "axios";
import type {
  StrapiResult,
  StrapiResultSingle,
  Policy,
  StrapiPolicy,
  StrapiEditorialTexts,
  EditorialTexts,
  StrapiTeamMember,
  TeamMember,
} from "@/models/strapi";
import { ENDPOINTS } from "@/services/ApiEndpoints";
import { getBaseStrapiUrl } from "@/services/Helpers";

const BASE_URL = getBaseStrapiUrl();
const MIN_WAIT_TIME = 900; // in ms

type ApiData = {
  policies: Policy[];
  state: "loading" | "ready" | "error";
  editorialTexts: EditorialTexts;
  teamMembers: TeamMember[];
  // eslint-disable-next-line
  dialog: { type: "closed" | "show-publications"; data: any };
};

export const useApiStore = defineStore("api", () => {
  const data = ref<ApiData>({
    state: "loading",
    policies: [],
    editorialTexts: { id: 0, Welcome: [], About: [] },
    teamMembers: [],
    dialog: { type: "closed", data: null },
  });

  // initialLoad: loads all strapi data for the entire application
  const initialLoad = async () => {
    if (data.value.state === "ready") {
      return false;
    }
    const minWait = new Promise((resolve) =>
      setTimeout(resolve, MIN_WAIT_TIME),
    );
    try {
      await Promise.all([
        fetchAllPolicies(),
        fetchEditorialTexts(),
        fetchTeamMembers(),
        minWait,
      ]);
      data.value.state = "ready";
    } catch (error) {
      console.error("Failed during initial load:", error);
      data.value.state = "error";
      throw Error;
    }
  };

  const fetchAllPolicies = async () => {
    try {
      const response = await axios.get<StrapiResult<StrapiPolicy>>(
        `${BASE_URL}${ENDPOINTS.policy}?populate=*`,
      );

      data.value.policies = response.data.data.map((item) => {
        const parsedItem = {
          id: item.id,
          ...item.attributes,
        };
        parsedItem.Sources = [];
        parsedItem.SourceDocuments = [];
        if (item.attributes.Sources) {
          // eslint-disable-next-line
          parsedItem.Sources = item.attributes.Sources.map((source: any) => {
            const parsedItem = {
              title: source.Title,
              url: source.URL,
            };
            return parsedItem;
          });
        }
        if (item.attributes.SourceDocuments.data) {
          parsedItem.SourceDocuments = item.attributes.SourceDocuments.data.map(
            // eslint-disable-next-line
            (sourceDoc: any) => {
              const parsedItem = {
                title: sourceDoc.attributes.name,
                url: getBaseStrapiUrl() + sourceDoc.attributes.url,
              };
              return parsedItem;
            },
          );
        }

        return parsedItem;
      });
    } catch (error) {
      console.error("Failed to fetch policies:", error);
      throw Error;
    }
  };

  const fetchEditorialTexts = async () => {
    try {
      const response = await axios.get<
        StrapiResultSingle<StrapiEditorialTexts>
      >(`${BASE_URL}${ENDPOINTS.editorialTexts}`);

      data.value.editorialTexts.Welcome = response.data.data.attributes.Welcome;
      data.value.editorialTexts.About = response.data.data.attributes.About;
    } catch (error) {
      console.error("Failed to fetch editorial texts:", error);
      throw Error;
    }
  };

  const fetchTeamMembers = async () => {
    try {
      const response = await axios.get<StrapiResult<StrapiTeamMember>>(
        `${BASE_URL}${ENDPOINTS.teamMembers}?populate=*`,
      );

      data.value.teamMembers = response.data.data.map((item) => {
        const parsedItem: TeamMember = {
          id: item.id,
          Full_Name: item.attributes.Full_Name,
          Position_Title: item.attributes.Position_Title,

          Institution_Department: item.attributes.Institution_Department,
          Website: item.attributes.Website,
          // eslint-disable-next-line
          Publications: item.attributes.Publications.map((item: any) => {
            return {
              title: item.Title,
              date: new Date(item.PublicationDate),
            };
          }),
          image: _getImages(
            item.attributes.Foto &&
              item.attributes.Foto.data &&
              item.attributes.Foto.data.attributes
              ? item.attributes.Foto.data.attributes
              : { formats: { small: null, medium: null, large: null } },
          ),
        };

        parsedItem.Publications.sort(
          (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
        );

        return parsedItem;
      });
    } catch (error) {
      console.error("Failed to fetch team members:", error);
      throw Error;
    }
  };
  // eslint-disable-next-line
  const _getImages = (data: any) => {
    return {
      small: {
        url: data.formats.small
          ? getBaseStrapiUrl() + data.formats.small.url
          : placeholderImage,
      },
      medium: {
        url: data.formats.medium
          ? getBaseStrapiUrl() + data.formats.medium.url
          : placeholderImage,
      },
      large: {
        url: data.formats.large
          ? getBaseStrapiUrl() + data.formats.large.url
          : placeholderImage,
      },
    };
  };

  return {
    data,
    initialLoad,
  };
});
